import CONFIG from '../CONFIG.json';

export const workWithLocalStorage = (action,key,value) =>{
    if (window.localStorage) {
        if(action==='set'){
            localStorage.setItem(key, value);
            return "";
        }else if(action==='get'){
            return localStorage.getItem(key);
        }else if(action==='remove'){
            localStorage.removeItem(key);
            return "";
        }
    }
}

export const isEmpty = (obj) => {
  if(obj === undefined || obj === null){
    return true;
  }else{
    return Object.keys(obj).length === 0;
  }
}

export const getURLImageCata = (qr,id,idRef,carpeta) =>{
    return CONFIG.api+'CatalogoCli?Action=DescargarFichero&qr='+qr+'&id='+id+'&idRef='+idRef+'&carpeta='+carpeta;
}

export const getCatalogoStorage = () =>{
  return JSON.parse(workWithLocalStorage('get','catalogo',''))
}

export const getLogoProp = (urlDef) => {
    const catalogo= JSON.parse(workWithLocalStorage('get','catalogo',''))
    const cata = catalogo.Catalogo
    if(cata.logoid !== ''){
      const url = getURLImageCata(cata.qr,cata.logoid,cata.id,'catalogo/'+cata.id+'/logo')
      return url
    }else{
      return urlDef
    }
};

export const getPortadaProp = () => {
    const catalogo= JSON.parse(workWithLocalStorage('get','catalogo',''))
    const cata = catalogo.Catalogo
    if(cata.portadaid !== ''){
      const url = getURLImageCata(cata.qr,cata.portadaid,cata.id,'catalogo/'+cata.id+'/portada')
      return url
    }else{
      return ''
    }
};

export const getVariacionesDeProducto = (id) => {
    const catalogo= JSON.parse(workWithLocalStorage('get','catalogo',''))
    const cata = catalogo.Extras.Productos
    const prod = cata.find(element => element.id === id);
    if(prod.variaciones !== '[]'){
      const vars = JSON.parse(prod.variaciones)
      const variaciones = vars.map((v,key) => {
          let nombre = ''
            nombre = nombre.concat(v.variaciones.map((n,k) =>{
                return n.atributo+': '+n.nombre
            }))
            nombre = nombre.replace(',',' / ')
            return   {label: nombre, value: v.variacionid, key: v.variacionid}
          })
      return variaciones;
    }else{
      return [];
    }
  };

export const getVariacionDeProducto = (id,idV) => {
      const catalogo= JSON.parse(workWithLocalStorage('get','catalogo',''))
      const cata = catalogo.Extras.Productos
      const prod = cata.find(element => element.id === id);
      if(prod.variaciones !== '[]'){
        const vars = JSON.parse(prod.variaciones)
        const vari = vars.find(element => element.variacionid === idV);
        return vari;
      }else{
        return {};
      }
    };

export const getImagenesDeProducto = (id) => {
    const catalogo= JSON.parse(workWithLocalStorage('get','catalogo',''))
    const cata = catalogo.Extras.Productos
    const prod = cata.find(element => element.id === id);
    if(prod.imagenes !== ''){
      const imagenes = prod.imagenes.split(',').map((img,key) => {
            const imgid = img.split(';')[0]
            const url = getURLImageCata(catalogo.Catalogo.qr,imgid,prod.id,'productos')
            return   {url: url, nombre: 'imagen'+key}
          })
      return imagenes;
    }else{
      return [];
    }
  };

export const getProductosPorCategoria = (catid,proid) => {
      const catalogo= JSON.parse(workWithLocalStorage('get','catalogo',''))
      const cata = catalogo.Extras.Productos
      const prod = cata.filter(element => element.idCategoria === catid && element.id !== proid);
      return prod;
    };

export const getCatalogo =  () => {
  if (window.location.host.split('.').length === 3) {
    const qr = window.location.host.split('.')[0]
    if (/^[0-9]{1,6}[\D]{4}/i.test(qr.toUpperCase())) {
      fetch(CONFIG.api + "CatalogoCli?Action=getFullCatalogo&qr=" + qr)
        .then(res => res.json())
        .then((result) => {
          if (result.length !== 0) {
            workWithLocalStorage('set','catalogo',JSON.stringify(result))
            return 1
          } else {
            workWithLocalStorage('remove','catalogo','')
            return -1
          }
        })
    } else {
      return -2
    }
  }else{
    return 0
  }
}
