
import React, { lazy, Component } from "react";
import { Switch, Route } from "react-router-dom";
import Cuerpo from "./modulos/cuerpo"
import CONFIG from './CONFIG.json';
import { isEmpty, getCatalogoStorage, workWithLocalStorage, getProductosPorCategoria } from "./auxiliares/funciones";

// product pages
const Producto = lazy(() => import("./paginas/producto"));


// other pages
/*const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));*/

const Error = lazy(() => import("./paginas/error"));

const SeleccionarTienda = lazy(() => import("./paginas/seleccionarTienda"));
const CatalogoTienda = lazy(() => import("./paginas/catalogoTienda"));
const Contacto = lazy(() => import("./paginas/contacto"));


const PoliticaPrivacidad = lazy(() => import("./paginas/politicaPrivacidad"));
const AvisoLegal = lazy(() => import("./paginas/avisoLegal"));
const Cookies = lazy(() => import("./paginas/cookies"));

  class App extends Component {
    constructor(props) {
      super(props);
        this.state = {
          urlValida:null
        };
    }

    componentWillMount(){
      if (window.location.host.split('.').length === 3) {
        const qr = window.location.host.split('.')[0]
        if (/^[0-9]{1,6}[\D]{4}/i.test(qr.toUpperCase())) {
           const cataAct=getCatalogoStorage()
           fetch(CONFIG.api + "CatalogoCli?Action=getFullCatalogo&qr=" + qr)
             .then(res => res.json())
             .then((result) => {
               if (!isEmpty(result.Catalogo)) {
                 if(cataAct === null){
                     workWithLocalStorage('set','catalogo',JSON.stringify(result))
                     workWithLocalStorage('set','theme_color',result.Catalogo.color)
                     window.location =CONFIG.protocolo + '://' +  qr+"."+CONFIG.url_basic+window.location.pathname
                     this.setState({urlValida:2})
                 }else if(JSON.stringify(cataAct) !== JSON.stringify(result)){
                     workWithLocalStorage('set','catalogo',JSON.stringify(result))
                     workWithLocalStorage('set','theme_color',result.Catalogo.color)
                     window.location =CONFIG.protocolo + '://' +  qr+"."+CONFIG.url_basic+window.location.pathname
                     this.setState({urlValida:2})
                 }else{
                      workWithLocalStorage('set','theme_color',result.Catalogo.color)
                      this.setState({urlValida:1})
                 }
               }else{
                 workWithLocalStorage('remove','theme_color','')
                 this.setState({urlValida:-1})
                 window.location = CONFIG.protocolo + '://' +  CONFIG.url_basic+window.location.pathname+'?e=1'
               }
             })
           } else {//subdominio formato incorrecto
             workWithLocalStorage('remove','theme_color','')
             this.setState({urlValida:-2})
             window.location = CONFIG.protocolo + '://' +  CONFIG.url_basic+window.location.pathname
           }
     }else{//No tiene subdominio
       workWithLocalStorage('remove','theme_color','')
       this.setState({urlValida:0})
     }
    }

    render(){
    return (
      <Cuerpo>
              { this.state.urlValida < 0 ?
                "" //subdominio formato incorrecto o qr inválido
                : this.state.urlValida === 0 ?
                <SeleccionarTienda/>//No tiene subdominio
              : this.state.urlValida === 1 ?
                <Switch>
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    render={(routeProps) => (
                      <CatalogoTienda products={getCatalogoStorage().Extras.Productos} />
                    )}
                  />

                  {/* Shop product pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/producto/:id"}
                    render={(routeProps) => (
                      <Producto {...routeProps}id={routeProps.match.params.id} key={routeProps.match.params.id} />
                    )}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/categoria/:id"}
                    render={(routeProps) => (
                      <CatalogoTienda products={getProductosPorCategoria(routeProps.match.params.id,'0')} id={routeProps.match.params.id} pagina="categorias" key={routeProps.match.params.id} />
                    )}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/contacto"}
                    component={Contacto}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/politica-privacidad"}
                    component={PoliticaPrivacidad}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/aviso-legal"}
                    component={AvisoLegal}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/cookies"}
                    component={Cookies}
                  />

                  {/* Other pages */}
                  {/*<Route
                    path={process.env.PUBLIC_URL + "/about"}
                    component={About}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/my-account"}
                    component={MyAccount}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/login-register"}
                    component={LoginRegister}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/cart"}
                    component={Cart}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/wishlist"}
                    component={Wishlist}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/compare"}
                    component={Compare}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/checkout"}
                    component={Checkout}
                  />*/}

                  <Route
                    path={process.env.PUBLIC_URL + "/error"}
                    component={Error}
                  />

                  <Route exact component={Error} />
                </Switch>
                : "" //Toca refrescar
              }
      </Cuerpo>
    );
  }
};

export default App;
