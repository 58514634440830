import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./style.css";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
//import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
//import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import CONFIG from './CONFIG.json';

import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

// fetch products from json file
//store.dispatch(fetchProducts(products));

let color = localStorage.getItem("theme_color") ? localStorage.getItem("theme_color") : CONFIG.theme_color;
    if(color.length<2){
      color = CONFIG.theme_color
    }

localStorage.setItem("theme_color", color);

import('./assets/scss/temas/' + color + '.scss').then(x => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
